import React from 'react'
import './Utility.css'
import lineb from '../assets/images/lineb.png'
import lineb1 from '../assets/images/lineb1.png'
import lineb2 from '../assets/images/lineb2.png'
const Utility = ({theme}) => {
    return (
        <div className='utility'>
            <div className='utility-border'>
                <div className='utility-title'>
                    Utility
                    <div className='utility-lineb'>
                        {/* <img src={lineb} alt='lineb' /> */}
                        {theme === 'light' ?<img src={lineb} alt='lineb' />:theme === 'dark' ?<img src={lineb2} alt='lineb' />:<img src={lineb1} alt='lineb' />}
                    </div>

                </div>
                <div className='utility-content'>
                    <div className='utility-content-description'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua..
                    </div>
                </div>
                <div className='utilities-imgs'>
                    <div className='row1'>

                        <div className='computing'>
                            COMPUTING
                        
                        </div>
                        <div className='education'>
                        EDUCATION
                        </div>
                    </div>
                    <div className='row2'>
                        <div className='finance'>
                        FINANCE
                        </div>

                        <div className='dailyuse'>
                        DAILY USE
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Utility