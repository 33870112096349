import React, { useState } from 'react';
import './App.css';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalStyle, lightTheme, darkTheme, redTheme } from './styles/globalStyles';
import { useDarkMode } from './styles/useDarkMode';
import DeleteAccountRequest from './deleteAccount/DeleteAccountRequest';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from "./MainPage/MainPage";

const Container = styled.div``;

function App() {
  const [loading, setLoading] = useState(true);
  const spinner = document.getElementById("preloader");
  if (spinner) {
    setTimeout(function () {
      spinner.style.opacity = 1;
      (function fade() {
        (spinner.style.opacity -= 0.1) < 0 ? (spinner.style.display = "none") : setTimeout(fade, 170)
      })()
    }, 1500)
  }

  const [theme, toggleTheme] = useDarkMode();
  const themeMode = theme === 'light' ? lightTheme : theme === 'dark' ? darkTheme : redTheme;

  return (
    <>
      <Router>
        <ThemeProvider theme={themeMode}>
          <Container>
            <GlobalStyle />
            <Routes>
              <Route path="/" element={<MainPage theme={theme} toggleTheme={toggleTheme} />} />
              <Route path="/deleteaccount" element={<DeleteAccountRequest />} />
            </Routes>
          </Container>
        </ThemeProvider>
      </Router>
    </>
  );
}

export default App;