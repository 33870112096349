import React, { useState } from "react";
import randomtheme from "../assets/images/random theme.png";
import mobileapp from "../assets/images/mobileapp.png";
import webapp from "../assets/images/webapp.png";
import desktopapp from "../assets/images/desktopapp.png";
import "./Sidenav.css";
import windowApp from "../assets/downloads/AvaInstaller@PathOr.exe";
import { useIdleTimer } from "react-idle-timer";

const Sidenav = ({ toggleTheme }) => {
  const sideNavContainer = document.getElementById("sideNavContainer");
  const timeout = 2300;
  const [remaining, setRemaining] = useState(timeout);
  const [isIdle, setIdle] = useState(false);
  const HandleonIdle = () => {
    console.log("Idleee");
    setIdle(true);
    // if(sideNavContainer.hasClass('active'))
    // sideNavContainer.addClass('idleUser')
    // Close Modal Prompt
    // Do some idle action like log out your user
  };

  const HandleonActive = (event) => {
    console.log("Active");
    setIdle(false);
    // Close Modal Prompt
    // Do some active action
  };
  const { getRemainingTime } = useIdleTimer({
    timeout,
    onIdle: HandleonIdle,
    onActive: HandleonActive,
  });

  return (
    <section
      className={isIdle ? "landing-leftIdle" : "landing-leftActive"}
      id="sideNavContainer"
    >
      <div className="bg-sidenav">
        <a
          href="https://www.dropbox.com/s/1zxermiw27pceym/AvaInstaller%40PathOr.exe?dl=1"
          target="_blank"
        >
          <div className="appi">
            <img src={desktopapp} alt="desktop-app" />
          </div>
        </a>
        <div className="appi">
          <img src={webapp} alt="web-app" />
        </div>
        <div className="appi">
          <img src={mobileapp} alt="mobile-app" />
        </div>
        <div className="appi">
          <img src={randomtheme} alt="random-theme" onClick={toggleTheme} />
        </div>
      </div>
    </section>
  );
};

export default Sidenav;
