import React, { useState } from "react";
import "./Navbar.css";
import Headroom from "react-headroom";
import pathorlogo from "../assets/images/pathor_logo_tp-removebg-preview.png";
const Navbar = () => {
  const [colorChange, setColorchange] = useState(false);
  const changeNavbarColor = () => {
    if (window.scrollY >= 120) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);
  return (
    <Headroom>
      <nav className={colorChange ? "navbar" : "navbar-colourchange"}>
        <div className="navbar-content">
          <span className="logo">
            <img src={process.env.PUBLIC_URL + "/Ava_Logo1.png"} />
          </span>
          <ul className="items-list">
            <li className="items-content">Features</li>
            <li className="items-content">Utility</li>
            <li className="items-content">
              <a
                href=""
                target="_blank"
              >
                <button className="download">Download</button>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </Headroom>
  );
};

export default Navbar;
