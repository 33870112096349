import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

/* General */
:root {
  --ascent-clr-dark: #3282b8;
  --ascent-clr: #46b5d1;
  --bg-color: #14181a;
  --text-color: #eeeeee;
}
::-webkit-scrollbar {
  width: 3px;
  background-color: var(--bg-color);
  /* display:none; */
}
html {
  scroll-behavior: smooth;
}
::-webkit-scrollbar-track {
  box-shadow: inset 2px 2px 20px rgba(0, 0, 0, 0.2);
  background-color: var(--bg-color);
  background-clip: content-box;
}

::-webkit-scrollbar-thumb {
  background-color: var(--ascent-clr);
  border-radius: 4px;
}
.utility,.feature,.navbar {
  background-color: ${({ theme }) => theme.primary};
}
.home-bg{

  height: 100vh;
}

.ava{
  background: linear-gradient(${({ theme }) => theme.primary},${({ theme }) => theme.primary} 50%,${({ theme }) => theme.secondary});
}

.footer{
  color:${({ theme }) => theme.text}
}

.logo{
  color:${({ theme }) => theme.text}
}

.utility-border{
  
  border-left: 4px solid ${({ theme }) => theme.secondary};
  border-right: 4px solid ${({ theme }) => theme.secondary};
}

.ava-left{
  background: linear-gradient(${({ theme }) => theme.secondary},${({ theme }) => theme.secondary} 30%,${({ theme }) => theme.primary});
}
@media (max-width:768px) {

  .ava-left {
    background: inherit;
  }
  .utility-border{
  
    border-left: none;
    border-right: none;
  }


}
`
export const lightTheme = {
  body: '#F7F7F7',
  text: '#33AFEB',
  primary: '#090A21',
  secondary: '#083C54',
  tertiary: '#1D3049',

};
export const darkTheme = {
  body: '#121212',
  text: '#33AFEB',
  primary: '#2D1127',
  secondary: '#C7614C',
  tertiary: '#1D3049',
};

export const redTheme = {
  body: '#121212',
  text: '#535CF9',
  primary: '#090B22',
  secondary: '#49005D',
};