import React from "react";
import "./Footer.css";
import insta from "../assets/images/insta.png";
import linkedin from "../assets/images/linkedin.png";
import twitter from "../assets/images/twitter.png";

const Footer = () => {
  return (
    <div className="footer">
      <section className="footer-left">
        <div className="pathor">
          <div className="pathor-title"> PathOr Platforms </div>
          <div className="address">
            <p>Address : Lorem ipsum</p>
          </div>
        </div>
        <div className="links">
          <div className="insta">
            <img src={insta} alt="insta" />
          </div>
          <div className="twitter">
            <img src={twitter} alt="twitter" />
          </div>
          <div className="linkedin">
            <img src={linkedin} alt="linkedin" />
          </div>
        </div>
      </section>
      <section className="footer-right">
        <div className="aboutus">
          <div className="aboutus-title">
            <h6>About Us</h6>
          </div>
          <div className="faq">
            <p>FAQ</p>
          </div>
          <div className="contact">
            <p>Contact Us</p>
          </div>
        </div>
        <div className="privacy">
          <div className="privacy-title">
            <h6>Privacy</h6>
          </div>
          <div className="terms">
            <p>
              <a
                href="https://www.pathor.in/assets/ava/termscondition"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Terms and Conditions
              </a>
            </p>
          </div>
          <div className="policy">
            <p>
              <a
                href="https://www.pathor.in/assets/ava/privacypolicy"
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
