import React from 'react'
import Landing from '../landing/Landing'
import Navbar from '../navbar/Navbar'
import './Home.css'
import bgmov1 from '../assets/images/bgv1.mp4'
import vid1 from '../assets/videos/vid1.mp4'
import vid5 from '../assets/videos/vid5.mp4'
import vid3 from '../assets/videos/vid3.mp4'
import vid4 from '../assets/videos/vid4.mp4'
import { useEffect } from 'react'




const Home = ({ theme }) => {
  // useEffect(() => {

  // },loading)

  return (
    <div className='container-fluid'>

      <div className='home-bg'>
        <div
          className="home-video">
          <div className="overlay"></div>
          {theme === 'light' ?
            <video src={vid1}
              autoPlay muted loop
              width='100%'>
              <source ></source>
            </video> : theme === 'dark' ? <video src={vid5}
              autoPlay muted loop
              width='100%'>
              <source ></source>
            </video> : <video src={vid4}
              autoPlay muted loop
              width='100%'>
              <source ></source>
            </video>
          }
        </div>
        <Navbar />
        <Landing />
      </div>
    </div>
  )
}

export default Home