import { useEffect } from "react"
import React from "react"
import Footer from "../footer/Footer"
import Sidenav from "../sidenav/Sidenav"
import Utility from "../utility/Utility"
import Home from "../home/Home"
import Feature from "../feature/Feature"
import Ava from "../ava/Ava"
import { GlobalStyle } from "../styles/globalStyles"

const MainPage=({theme ,toggleTheme})=>{

return(
    <>
            <GlobalStyle /> 
            <Home theme={theme}/>
            <Sidenav theme={theme} toggleTheme={toggleTheme} />
            <Feature theme={theme} />
            <Utility theme={theme} />
            <Ava theme={theme} /> 
            <Footer />
    </>
)


}

export default MainPage