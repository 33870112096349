import React, { useState } from 'react'
import './Feature.css'
import f1 from '../assets/images/feature1.png'
import f2 from '../assets/images/feature2.png'
import { AiOutlinePlus } from 'react-icons/ai'
import { AiOutlineMinus } from 'react-icons/ai'
import lineb from '../assets/images/lineb.png'
import lineb1 from '../assets/images/lineb1.png'
import lineb2 from '../assets/images/lineb2.png'
const Feature = ({ theme }) => {
    const [openacc1, setOpenacc1] = useState(true)
    const [openacc2, setOpenacc2] = useState(false)
    const [openacc3, setOpenacc3] = useState(false)



    return (
        <div className='feature'>
           
            <section className='right-feature'>
                <div className='feature-border'>
                    <div className='feature-title'>
                        Feature Set
                    </div>
                    <div className='feature-lineb'>
                        {theme === 'light' ? <img src={lineb} alt='lineb' /> : theme === 'dark' ? <img src={lineb2} alt='lineb' /> : <img src={lineb1} alt='lineb' />}
                    </div>

                </div>
                <div className='accordion' >

                    <button class="accordion-btn" onMouseEnter={() => { setOpenacc1(!openacc1); setOpenacc2(false); setOpenacc3(false);  }}><div className='feature-content'>Feature 1</div><div className='acc-icons'>{openacc1 ? <AiOutlineMinus /> : <AiOutlinePlus />}</div></button>
                    {openacc1 ?
                        <div class="panel">
                            <p className='feature-sub-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                        </div> : null}

                    <button class="accordion-btn" onMouseEnter={() => { setOpenacc2(!openacc2); setOpenacc1(false); setOpenacc3(false);}}><div className='feature-content'>Feature 2</div><div className='acc-icons'>{openacc2 ? <AiOutlineMinus /> : <AiOutlinePlus />}</div></button>
                    {openacc2 ?
                        <div class="panel">
                            <p className='feature-sub-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                        </div> : null}

                    <button class="accordion-btn" onMouseEnter={() => { setOpenacc3(!openacc3); setOpenacc1(false); setOpenacc2(false); }}><div className='feature-content'>Feature 3</div><div className='acc-icons'>{openacc3 ? <AiOutlineMinus /> : <AiOutlinePlus />}</div></button>
                    {openacc3 ?
                        <div class="panel">
                            <p className='feature-sub-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                        </div> : null}


                </div>
            </section>
            <section className='left-feature'>
                {openacc1 ? <div className='feature-image-1'><img src={f1} alt='f1' /></div> : openacc2}
                {openacc2 ? <div className='feature-image-2'><img src={f2} alt='f2' /></div> : openacc3}
                {openacc3 ? <div className='feature-image-1'><img src={f1} alt='f1' /></div> : openacc1}

                {openacc1 ? <div className='feature-image-2'><img src={f2} alt='f2' /></div> : openacc2}
                {openacc2 ? <div className='feature-image-1'><img src={f1} alt='f1' /></div> : openacc3}
                {openacc3 ? <div className='feature-image-2'><img src={f2} alt='f2' /></div> : openacc1}

            </section>
        </div>
    )
}

export default Feature