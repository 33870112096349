import React from "react";
import "./Ava.css";
import ava from "../assets/images/ava.png";
import ava1 from "../assets/images/ava1.png";
const Ava = ({ theme }) => {
  return (
    <div className="ava">
      <div className="ava-section">
        <section className="ava-left">
          <div className="whyava">Why AVA?</div>
          <div className="ava-content">
            Ava, your ultimate AI companion, is here to boost productivity and
            make life easier. Say hello to seamless assistance and a brighter
            future with Ava by your side! Let's embark on this exciting journey
            together! 🌟
          </div>
        </section>
        <section className="ava-right">
          {theme === "light" ? (
            <img src={ava} alt="ava"></img>
          ) : theme === "dark" ? (
            <img src={ava} alt="ava"></img>
          ) : (
            <img src={ava1} alt="ava"></img>
          )}
        </section>
      </div>
    </div>
  );
};

export default Ava;
