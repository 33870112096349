import { useState } from 'react'

export const useDarkMode = () => {
    const [theme,setTheme]=useState('light');

    const toggleTheme = () => {
        // theme === 'light'? setTheme('dark') : 'red' ? setTheme('light') : setTheme('red'); 
        if(theme === 'light'){
            setTheme('dark')
        }
        else if(theme === 'dark'){
            setTheme('red')
        }
        else{
            setTheme('light')
        }
    }
    return [ theme , toggleTheme]
}
