import React, { useEffect, useState } from "react";
import "./DeleteAccountRequest.css";
import axios from "axios";

const DeleteAccountRequest = () => {
  const [email, setEmail] = useState("");
  const [messageBOx, setMessageBox] = useState(false);
  const [statusText, setStatusText] = useState(
    "Your account deletion request has been successfully submitted. We'll process it promptly.🙂"
  );
  // const [loader , setLoader] = useState(true);

  // const spinner = document.getElementById("mainLoader");
  // if (loader) {
  //   setTimeout(function () {
  //     spinner.style.opacity = 1;
  //     (function fade() {
  //       (spinner.style.opacity -= 0.1) < 0 ? (spinner.style.display = "none") : setTimeout(fade, 170)
  //     })()
  //   }, 1500)
  // }

  const baseURL = process.env.REACT_APP_BASE_URL;

  var messageData = null;
  const hadleDeleteUserEmail = () => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseURL + "/api/v1/users/admin/deleteUser/" + email,
    };

    axios
      .request(config)
      .then((response) => {
        messageData = response.data;
        console.log(JSON.stringify(response.data));
        console.log(messageData);
        if (response.data.message) {
          setStatusText(response.data.message);
        }
        setMessageBox(true);
      })
      .catch((error) => {
        console.log(error);
        if (error.data.data.message) {
          setStatusText(error.data.message);
        }
        setMessageBox(true);
      });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    hadleDeleteUserEmail();
    // setLoader();
  };

  const handleClickOk = () => {
    setMessageBox(false);
  };

  return (
    <div className="main" id="mainLoader">
      <div className="heading">💀 Account Deletion Request 🗑️</div>
      <div className="card">
        <div className="text">
          By entering your email address assosciated with your account, we will
          process your account deletion within 7 days. However, your account
          will be still accessible for the time being the account is deleted
          (maximum 7 dyas). You will be notified once the account deletion
          request is completed. The 7 days time allows you to cancel or retrieve
          back your account if needed.
          <div className="text" style={{ color: "red", fontSize: "18px" }}>
            *An confirmation email is sent before performing the deletion
            request for your account!
          </div>
        </div>
        <form onSubmit={handleSubmit} className="form-container">
          <label>
            Email:
            <input
              type="email"
              value={email}
              onChange={handleEmailChange}
              required
            />
          </label>
          <button type="submit">Submit</button>
        </form>
      </div>
      {messageBOx && (
        <div className="main_popup">
          <div className="popup">
            <div className="innerPopup">
              <div className="message_text">
                {messageData ? (
                  messageData.status === "true" ||
                  messageData.status === true ? (
                    <p>{statusText}</p>
                  ) : (
                    <p>{messageData.message}</p>
                  )
                ) : (
                  <p>Something went wrong🤔</p>
                )}
              </div>
              <div className="ok_button">
                <button onClick={handleClickOk}>Ok</button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAccountRequest;
