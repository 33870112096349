import React from "react";
import "./Landing.css";

const Landing = ({ theme, toggleTheme }) => {
  return (
    <div className="container-fluid">
      <div className="landing">
        <section className="landing-right">
          <div className="landing-content">
            <div className="landing-title">AVA</div>
            <div
              className="landing-about"
              style={{ textAlign: "center", width: "50%" }}
            >
              Ava, your ultimate AI companion, is here to boost productivity and
              make life easier. Say hello to seamless assistance and a brighter
              future with Ava by your side! Let's embark on this exciting
              journey together! 🌟
            </div>
            <button className="visitava">Visit AVA</button>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Landing;
